// const country = ["Afghanistan","Albania","Algeria","Andorra","Angola","Anguilla",
//
//     "Antigua","Argentina","Armenia","Aruba","Australia","Austria","Azerbaijan","Bahamas",
//
//     "Bahrain","Bangladesh","Barbados","Belarus","Belgium","Belize","Benin","Bermuda",
//
//     "Bhutan","Bolivia","Bonaire","Bosniahercegovina","Botswana","Brazil","Brunei","Bulgaria",
//
//     "Burkinafaso","Burundi","Cambodia(Kampuchea)","Cameroon","Canada","Canary Islands","Cape Verde",
//
//     "Caymanislands","CÃ´te d'Ivoire","Central African Republ","Chad","Chile","China","Colombia","Comoros",
//
//     "Congo","Congo, The Democratic","Cook Islands","Costa Rica","Croatia","Cuba","Curacao","Cyprus",
//
//     "Czech Republic","Denmark","Djibouti","Dominica","Dominican Republic","East Timor","Ecuador",
//
//     "Egypt","El Salvador","Equatorial Guinea","Eritrea","Estonia","Ethiopia","Falklandislands",
//
//     "Faroe Islands","Fiji","Finland","France","Frenchguiana","Gabon","Gambia","Georgia","Germany",
//
//     "Ghana","Gibraltar","Greece","Greenland","Grenada","Guadeloupe","Guam","Guatemala","Guernsey",
//
//     "Guinea Republic","Guinea-Bissau","Haiti","Honduras","Hungary","Iceland","India","Indonesia",
//
//     "Iran","Iraq","Ireland","Israel","Italy","Jamaica","Japan","Jersey Island","Jordan","Kazakhstan",
//
//     "Kenya","Kiribati","Kosovo","Kuwait","Kyrgyzstan","Laos","Latvia","Lebanon","Lesotho","Liberia",
//
//     "Libya","Liechtenstein","Lithuania","Luxembourg","Macau","Macedonia, Republic Of",
//
//     "Madagascar","Malawi","Malaysia","Maldives","Mali","Malta","Marshallislands","Martinique",
//
//     "Mauritania","Mauritius","Mayotte","Mexico","Moldova Republic Of ","Mongolia","Montenegro",
//
//     "Monteserrat","Morocco","Mozambique","Myanmar","Namibia","Nauru Republic Of","Nepal",
//
//     "Netherlands","Nevis","New Caledonia","New Zealand","Nicaragua","Niger","Nigeria","Niue",
//
//     "North Korea","Norway","Oman","Pakistan","Panama","Papua New Guinea","Paraguay","Peru ",
//
//     "Philippines","Poland","Portugal","Puerto Rico","Qatar","Reunion Island Of","Romania",
//
//     "Russian Federation","Rwanda","Saipan","Samoa","San Marino","Sano Tome&Principe",
//
//     "Saudi Arabia","Senegal","Serbia","Seychelles","Sierra Leone","Singapore","Slovakia",
//
//     "Slovenia","Soloman Islands","Somalia","Somaliland Republic Of","South Africa","South Korea",
//
//     "Spain","Sri Lanka","Ssamoa","St. Barthelemy","St.Eustatius","St.Kitts","St.Lucia",
//
//     "St.Maarten","St.Vincent","Sudan","Suriname","Swaziland","Sweden","Switzerland","Syrian",
//
//     "Tahiti","Taiwan","Tajikistan","Tanzania","Thailand","Togo","Tonga","Trinidad & Tobago",
//
//     "Tunisia","Turkey","Turks&Caicos Islands","Tuvalu","Uganda","Ukraine","United Arab Emirates",
//
//     "United Kingdom","United States of America","Uruguay","Uzbekistan","Vanuatu","Venezuela",
//
//     "Vietnam","Virgin Islands(British","Virgin Islands(U.S)","Yemen","Zambia","Zimbabwe"];
const country = [
    "Afghanistan", "Albania", "Algeria", "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "Brunei Darussalam", "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verdian",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Ch",
    "China",
    "Christmas Island",
    "Cocos Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Congo Democratic Republic",
    "Cook Islands",
    "Costa Rica",
    "Côte d Ivoire",
    "Croatia",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands Malvinas",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard and McDonald",
    "Holy See Vatican City State",
    "Honduras",
    "Hong Kong SAR",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea Democratic Peoples Rep",
    "Korea Republic",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libyan Arab Jamahiriya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macau SAR",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia Federated States",
    "Moldavia",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "North Mariana",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reunion",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Saint Helena",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Pierre and Miquelon",
    "Saint Vincent and Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia and Montenegro",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and Sandwich IS",
    "Spain",
    "Sri Lanka",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan",
    "Tajikistan",
    "Tanzania United Republic",
    "Thailand",
    "Timor Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "US Minor Outlying Islands",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Viet Nam",
    "Virgin Islands British",
    "Virgin Islands US",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe"
]

export default country
