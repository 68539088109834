
import {ref, defineComponent, reactive, toRefs, onBeforeMount, onMounted} from 'vue'
import {ElMessageBox} from 'element-plus';
import { ElLoading } from 'element-plus';
import axios from '@/api/http.js';
import country from "@/commonData/country.js"


export default defineComponent({
  name: '',
  setup() {
    console.log("==== country=====",country)
    const registerFormRef = ref(null);
    const data = reactive({
      // form: {
      //   email: "136605477@qq.com",
      //   firstName: "Haocheng",
      //   lastName: "Xu",
      //   company: "zivix",
      //   title: "dev",
      //   isReceiveMaterials: "on",
      //   countryRegion: "United States",
      //   relation: "Customer",
      //   coutryOptions: [
      //     // {label: "China", value: "China"},
      //     // {label: "Japan", value: "Japan"},
      //     // {label: "Korea", value: "Korea"},
      //     // {label: "United States", value: "United States"},
      //     // {label: "England", value: "England"},
      //   ],
      //   relationOptions: [
      //     {label: "Partner", value: "Partner"},
      //     {label: "Distributor", value: "Distributor"},
      //     {label: "Employee", value: "Employee"},
      //     {label: "Customer", value: "Customer"},
      //   ],
      // },
      form: {
        email: "",
        firstName: "",
        lastName: "",
        company: "",
        title: "",
        isReceiveMaterials: "on",
        countryRegion: "",
        relation: "",
        coutryOptions: [
          // {label: "China", value: "China"},
          // {label: "Japan", value: "Japan"},
          // {label: "Korea", value: "Korea"},
          // {label: "United States", value: "United States"},
          // {label: "England", value: "England"},
        ],
        relationOptions: [
          {label: "Partner", value: "Partner"},
          {label: "Distributor", value: "Distributor"},
          {label: "Employee", value: "Employee"},
          {label: "Customer", value: "Customer"},
        ],
      },
      rules: {
        email: [
          {required: true, message: 'This field is required', trigger: 'blur'},
          // { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ],
        firstName: [
          {required: true, message: 'This field is required', trigger: 'blur'},
        ],
        lastName: [
          {required: true, message: 'This field is required', trigger: 'blur'},
        ],
        company: [
          {required: true, message: 'This field is required', trigger: 'blur'},
        ],
        title: [
          {required: true, message: 'This field is required', trigger: 'blur'},
        ],
        countryRegion: [
          {required: true, message: 'This field is required', trigger: 'change'},
        ],
        relation: [
          {required: true, message: 'This field is required', trigger: 'change'},
        ],
      }
    })
    const refData = toRefs(data);
    const clickSubmit = () => {
      registerFormRef.value.validate((valid) => {
        if (valid) {
         const loading = ElLoading.service({
        lock: true,
      })
          axios.request({
            method: 'POST',
            url: '/cms/addSpecialAttendee',
            data: {
              email: data.form.email,
              firstName: data.form.firstName,
              lastName: data.form.lastName,
              company: data.form.company,
              title: data.form.title,
              isReceiveMaterials: data.form.isReceiveMaterials,
              countryRegion: data.form.countryRegion,
              relation: data.form.relation,
            },
          }).then(res => {
            console.log('res:', res);
            console.log('text:', res.data.data.text);
            ElMessageBox.alert(res.data.data.text, 'Info', {
              confirmButtonText: 'OK',
            })
            loading.close();
          }).catch(error => {
            loading.close();
            if (error.response) {
              ElMessageBox.alert(error.response.data.error, 'Info', {
                confirmButtonText: 'OK',
              })
            } else if (error.request) {
              // The request was made but no response was received
              // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
              // http.ClientRequest in node.js
              console.log("error.request:",error.request);
            } else {
              // Something happened in setting up the request that triggered an Error
              console.log('Error!!', error.message);
            }
            // console.log("error.config:",error.config);
          })

        } else {
          return false
        }
      })
    }
    const initCountry = ()=>{
      let arr = [];
      country.map(item=>{
        let obj = {};
        obj["label"] = item;
        obj["value"] = item;
        arr.push(obj);
      })
      data.form.coutryOptions = arr;
    }
    initCountry();
    return {
      ...refData,
      clickSubmit,
      registerFormRef,
    }

  }
});
